import { createBiLogger } from '@common/providers';
import { getViewedMemberId } from '@common/utils';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { initActions, initStore } from './store';
import { initServices } from '@common/services';

export const createControllerV1 = async (
  flowAPI: ControllerParams['flowAPI'],
) => {
  const isSSR = flowAPI.environment.isSSR;

  const { setProps, wixCodeApi } = flowAPI.controllerConfig;
  const viewedUserId = getViewedMemberId(flowAPI);

  const fedopsLogger = flowAPI.fedops;
  const errorLogger = (error: Error) => {
    // @TODO add state update on error
    flowAPI.reportError(error);
  };
  const biLogger = createBiLogger(flowAPI.bi!, {
    defaultConfig: {
      src: 69,
      endpoint: 'forums-uou',
    },
    defaultParams: {
      origin: 'member_posts_page',
    },
  });

  const servicesConfig = initServices(flowAPI);
  const store = initStore(servicesConfig, errorLogger);
  const actions = initActions(
    wixCodeApi,
    store,
    errorLogger,
    biLogger,
    fedopsLogger,
  );

  await actions.initAppBase({
    // @TODO add language validation from available forum languages
    language: wixCodeApi.site.language || 'en',
  });
  await actions.initAppData({
    // @TODO check how to handle missing viewedUserId
    viewedUserId: viewedUserId || '',
  });

  // To handle user login in members area
  wixCodeApi.user.onLogin(async () => {
    await actions.fetchCurrentUser();
    await actions.fetchMemberPosts({}); // Refetching required, because anonymous users can't see all posts
  });

  if (!isSSR) {
    actions.logBiEvent({ evid: 118, type: 'member_posts_page' });
  }

  setProps({
    actions,
    state: store.getState(),
    fitToContentHeight: true,
  });

  store.subscribe(() => {
    setProps({
      actions,
      state: store.getState(),
    });
  });
};
