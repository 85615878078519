import { createSelector } from '@reduxjs/toolkit';

import { BASE_PROPS_SLICE_NAME } from './base-props-slice';
import { BasePropsState } from './base-props-slice.models';

export interface PartialState {
  [BASE_PROPS_SLICE_NAME]: BasePropsState;
}

export const getBasePropsSlice = (state: PartialState) =>
  state[BASE_PROPS_SLICE_NAME];

export const getBaseLanguage = createSelector(
  getBasePropsSlice,
  (slice) => slice.language,
);

export const getCurrentUser = createSelector(
  getBasePropsSlice,
  (slice) => slice.currentUser.data,
);

export const getCurrentUserId = createSelector(
  getCurrentUser,
  (currentUser) => currentUser?.userId,
);
