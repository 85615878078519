import { createSelector } from '@reduxjs/toolkit';

import { MEMBER_POSTS_SLICE_NAME } from './member-posts-slice';
import { MemberPostsState } from './member-posts-slice.models';

export interface PartialState {
  [MEMBER_POSTS_SLICE_NAME]: MemberPostsState;
}

export const getMemberPostsSlice = (state: PartialState) =>
  state[MEMBER_POSTS_SLICE_NAME];

export const getMemberPosts = createSelector(getMemberPostsSlice, (state) => {
  return state;
});
