import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { ServicesMap, CurrentUser } from '@common/services';

export type ThunkConfig = {
  extra: {
    services: ServicesMap;
  };
};

export const setBaseProps = createAction(
  'baseProps/setBaseProps',
  (baseProps: { language: string }) => {
    return {
      payload: baseProps,
    };
  },
);

export const fetchCurrentUser = createAsyncThunk<
  CurrentUser,
  undefined,
  ThunkConfig
>('baseProps/fetchCurrentUser', (_, { extra: { services } }) => {
  return services.memberService.getCurrentUserData();
});

export default { setBaseProps, fetchCurrentUser };
