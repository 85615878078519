import { createSlice } from '@reduxjs/toolkit';

import { ErrorsState } from './errors-slice.models';
import { setError } from './errors-slice.actions';

export const ERRORS_SLICE_NAME = 'errors';

const initialState: ErrorsState = {
  error: null,
};

const basePropsSlice = createSlice({
  name: ERRORS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setError, (state, { payload }) => {
      return {
        ...state,
        error: payload,
      };
    });
  },
});

export const reducer = basePropsSlice.reducer;
