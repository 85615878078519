import { dsl } from '@wix/yoshi-serverless/wrap';

export const memberPostsApi = dsl({
          functionName: 'memberPostsApi',
          fileName: 'api/member.api',
        });

export const memberCommentsApi = dsl({
          functionName: 'memberCommentsApi',
          fileName: 'api/member.api',
        });

export const deleteMemberComment = dsl({
          functionName: 'deleteMemberComment',
          fileName: 'api/member.api',
        });