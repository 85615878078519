import { IWixAPI } from '@wix/yoshi-flow-editor';
import { FORUM_APP_ID } from '@wix/communities-forum-universal/dist/src/constants/appsConfig.prod';

export const getCurrentInstance = (wixCodeApi: IWixAPI) => {
  const getAppToken = wixCodeApi.site.getAppToken;

  return (
    (getAppToken && getAppToken(FORUM_APP_ID)) ||
    wixCodeApi.user.currentUser.instance ||
    ''
  );
};
