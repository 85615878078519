import { Order } from '@wix/ambassador-forum-v1-post/types';
import { Badge } from '@wix/ambassador-badges-v3-badge/types';

export interface Owner {
  id: string;
  name: string;
  badges: Badge[];
}

export interface User {
  id: string;
}

export interface MemberUserData {
  user: User;
}

export interface PaginationConfig {
  itemCount: number;
  pageSize: number;
  pageCount: number;
  currentFilter: {
    offset: number;
    size: number;
    sort?: string;
    order?: Order;
    limit?: number;
  };
}

export enum Status {
  INITIAL = 'INITIAL',
  READY = 'READY',
  PENDING = 'PENDING',
  PENDING_AFTER_DELETE = 'PENDING_AFTER_DELETE',
  ERROR = 'ERROR',
  NEW_USER = 'NEW_USER',
}

interface InitialState {
  status: Status.INITIAL;
  user?: User;
}

interface ReadyState {
  status: Status.READY;
}

interface PendingState {
  status: Status.PENDING;
}

interface PendingAfterDeleteState {
  status: Status.PENDING_AFTER_DELETE;
}

interface ErrorState {
  status: Status.ERROR;
}

export type StatefulSlice<T> =
  | InitialState
  | ErrorState
  | ((ReadyState | PendingState | PendingAfterDeleteState) & T);
