import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { createRequest } from '@wix/communities-forum-client-commons';

import { getCurrentInstance } from '@common/utils/get-instance';
import { createMemberService } from './member';
import { createTranslationsService } from './translations';

export type ServicesMap = ReturnType<typeof initServices>;

export const initServices = (flowAPI: ControllerFlowAPI) => {
  const { wixCodeApi, appParams } = flowAPI.controllerConfig;
  const getInstance = () => getCurrentInstance(wixCodeApi);

  const baseServices = {
    httpClient: flowAPI.httpClient,
    baseApi: createRequest({
      baseUrl: appParams.baseUrls.apiBaseUrl,
      getInstance,
      locale: wixCodeApi.site.language,
      httpClient: flowAPI.httpClient,
    }),
    communitiesApiBase: createRequest({
      baseUrl: appParams.baseUrls.apiBaseUrlClient,
      getInstance,
      locale: wixCodeApi.site.language,
      httpClient: flowAPI.httpClient,
    }),
  };

  return {
    ...baseServices,
    memberService: createMemberService(baseServices),
    translationsService: createTranslationsService(baseServices, {
      baseUrl: appParams.baseUrls.translationsBaseUrl,
      translationsName: 'main',
    }),
  };
};
