import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

interface PublicRouterData {
  viewedUser?: { id: string };
}

export const getViewedMemberId = (flowApi: ControllerFlowAPI) => {
  const { window, user } = flowApi.controllerConfig.wixCodeApi;
  const routerData = window.getRouterPublicData<PublicRouterData>();

  const currentMemberId = user.currentUser.loggedIn
    ? user.currentUser.id
    : null;

  // Fallback to currentMemberId required for ADI since getRouterPublicData is unavaible
  return routerData?.viewedUser?.id ?? currentMemberId;
};
