import { combineReducers } from '@reduxjs/toolkit';

import {
  TRANSLATIONS_SLICE_NAME,
  reducer as translationsReducer,
  actions as translationsActions,
} from '@common/store/translations-slice';
import {
  BASE_PROPS_SLICE_NAME,
  reducer as basePropsReducer,
  actions as basePropsActions,
} from '@common/store/base-props-slice';
import {
  MEMBER_POSTS_SLICE_NAME,
  reducer as memberPostsReducer,
  actions as memberPostsActions,
} from '@common/store/member-slice/member-posts-slice';
import {
  ERRORS_SLICE_NAME,
  reducer as errorsReducer,
} from '@common/store/errors-slice';

export const rootReducer = combineReducers({
  [BASE_PROPS_SLICE_NAME]: basePropsReducer,
  [MEMBER_POSTS_SLICE_NAME]: memberPostsReducer,
  [TRANSLATIONS_SLICE_NAME]: translationsReducer,
  [ERRORS_SLICE_NAME]: errorsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const rootActions = {
  ...basePropsActions,
  ...memberPostsActions,
  ...translationsActions,
};
